import React, { useState } from 'react';
import { MapProvider, Map, Source, Layer } from 'react-map-gl';

import NotificationSystem from './NotificationSystem';
import { GpsLocationContainerProps, MapCoordinates, NotificationAlerts } from './types';
import { Routes } from './Routes';
import { LoadingLocation } from './LoadingLocation';

export const GpsRouteContainer: React.FC<GpsLocationContainerProps> = ({authData}: GpsLocationContainerProps) => {
  const [notifications, setNotifications] = useState<NotificationAlerts[]>([]);
  const [geojson, setGeojson] = useState(null);
  const [coordinates, setCoordinates] = useState<MapCoordinates>({latitude:0, longitude:0, zoom:3});
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v12';

  return (
    <div>
      <MapProvider>
        <Map  id="gpstrackfyroutemap"
              initialViewState={{ latitude: 0, longitude: 0, zoom: 3 }}
              style={{ height: '100vh' }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ''}
              mapStyle={mapStyle}
              scrollZoom={true} 
              {...coordinates} 
        >
          {(coordinates.latitude == 0) && <LoadingLocation/>}
          {<Routes setNotifications={setNotifications} setGeojson={setGeojson} setMapLocation={setCoordinates} />}
          {<NotificationSystem notifications={notifications} />}
          {geojson && (
            <Source id="route" type="geojson" data={geojson}>
              <Layer  id="route-line"
                      type="line"
                      paint={{"line-color": "#FFA500", "line-width": 3, "line-dasharray": [6, 3]}} />
            </Source>
          )}
        </Map>
      </MapProvider>
    </div>
  );
}