import React, { useState } from 'react';

import { Select } from '../../components/Select';
import { SelectOption } from '../../components/Select/types';

type TimeGranularitySelectProps = {
  label?: string;
  timeGranularitySelected: string;
  setTimeGranularitySelected: (value: string) => void;
};

const TimeGranularitySelect: React.FC<TimeGranularitySelectProps> = (props: TimeGranularitySelectProps) => {
  const [timeGranularityOps, setTimeGranularityOps] = useState<SelectOption[]>([
    {label: "diario", value:"daily"},
    {label: "semanal", value:"week"},
    {label: "mensal", value:"month"}
  ]);
  const { timeGranularitySelected, setTimeGranularitySelected, label } = props;
  
  return (
    <Select
      id="timeGranularity-select"
      label={label}
      //placeholder="Granularidade"
      options={timeGranularityOps}
      value={timeGranularitySelected}
      loading={false}
      onChanging={(event) => setTimeGranularitySelected(event.target.value)}
    />
  );
};

export default TimeGranularitySelect;
