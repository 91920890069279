import React from 'react';
import { DefaultIconProps } from '../types';

export const MonitoringMenuIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style, onClick }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.32 11.9992C20.92 11.9992 22 10.9992 21.04 7.7192C20.39 5.5092 18.49 3.6092 16.28 2.9592C13 1.9992 12 3.0792 12 5.6792V8.5592C12 10.9992 13 11.9992 15 11.9992H18.32Z"
        strokeWidth="1.5"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0004 14.7017C19.0704 19.3317 14.6304 22.6917 9.58042 21.8717C5.79042 21.2617 2.74042 18.2117 2.12042 14.4217C1.31042 9.39172 4.65042 4.95172 9.26042 4.01172"
        strokeWidth="1.5"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
