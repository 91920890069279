import React from 'react';

import * as S from '../../styles';
import '../../stylesheet.css';

type ResourcesTimeCompanySectionProps = {
    index: number;
    title: string;
    prodFormatted: number;
    ptFormatted: number;
    complFormatted: number;
    deslocFormatted: number;
    total: number;
    dailyTotal: boolean;
    cores: {
        produtivas: string;  
        pt: string;
        complementares: string;
        deslocamentos: string;
        improdutivas: string;
    }
}

const calcularPercentual = (valor: number, total: number) => parseFloat( ((valor / total) * 100).toFixed(2) );

export const ProfessionBar: React.FC<ResourcesTimeCompanySectionProps> = ({index, 
                                                                           title,
                                                                           prodFormatted, 
                                                                           ptFormatted,
                                                                           complFormatted,
                                                                           deslocFormatted,
                                                                           total,
                                                                           cores}) => (
    <S.CompanyBarsContiner>
        <S.FunctionTitle key={index + "_" + title}>{title}</S.FunctionTitle>
        <S.ReportBarsContiner key={index + "_" + title + "+values"}>
            {(prodFormatted > 0) &&
                <S.Bars title={`Produtivas: ${calcularPercentual(prodFormatted, total)}% (${prodFormatted})`}
                        backgroundColor= {cores.produtivas}
                        width={`${calcularPercentual(prodFormatted, total)}%`}>
                        {`${calcularPercentual(prodFormatted, total)}% (${prodFormatted} min)`} 
                </S.Bars>}
        
            {(ptFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(ptFormatted, total)}% (${ptFormatted})`}
                        backgroundColor= {cores.pt}
                        width={`${calcularPercentual(ptFormatted, total)}%`}>
                    {`${calcularPercentual(ptFormatted, total)}% (${ptFormatted} min)`}
                </S.Bars>}
                                                              
            {(complFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(complFormatted, total)}% (${complFormatted})`}
                        backgroundColor= {cores.complementares}
                        width={`${calcularPercentual(complFormatted, total)}%`}>
                    {`${calcularPercentual(complFormatted, total)}% (${complFormatted} min)`}
                </S.Bars>}
                                                              
            {(deslocFormatted > 0) &&
                <S.Bars title={`Deslocamentos: ${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted})`} 
                        backgroundColor= {cores.deslocamentos}
                        width={`${calcularPercentual(deslocFormatted, total)}%`}>
                    {`${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted} min)`}             
                </S.Bars>}
        
            {/*(improdFormatted > 0) &&
                <S.Bars title={`Improdutivas: ${calcularPercentual(improdFormatted, total)}%`} 
                        backgroundColor= {cores.improdutivas}
                        width={`${calcularPercentual(improdFormatted, total)}%`}>
                    {`${calcularPercentual(improdFormatted, total)}%`} 
                {/*</S.Bars>*/}
        </S.ReportBarsContiner>
    </S.CompanyBarsContiner>   
)

export const CompanyBar: React.FC<ResourcesTimeCompanySectionProps> = ({index, 
                                                                        title,
                                                                        prodFormatted, 
                                                                        ptFormatted,
                                                                        complFormatted,
                                                                        deslocFormatted,
                                                                        total,
                                                                        dailyTotal,
                                                                        cores}) => (
    <S.CompanyFooterBarsContiner dailyTotal={dailyTotal}>
        <S.CompanyTotalTitle key={"total_" + title}>Total {title}</S.CompanyTotalTitle>
        <S.CompanyBarContiner key={"total_bar" + title}>
            {(prodFormatted > 0) &&
                <S.Bars title={`Produtivas: ${calcularPercentual(prodFormatted, total)}% (${prodFormatted})`}
                        backgroundColor= {cores.produtivas}
                        width={`${calcularPercentual(prodFormatted, total)}%`}>
                    {`${calcularPercentual(prodFormatted, total)}% (${prodFormatted} min)`} 
                </S.Bars>}
                
            {(ptFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(ptFormatted, total)}% (${ptFormatted})`}
                        backgroundColor= {cores.pt}
                         width={`${calcularPercentual(ptFormatted, total)}%`}>
                    {`${calcularPercentual(ptFormatted, total)}% (${ptFormatted} min)`}
                </S.Bars>}
                                                                
            {(complFormatted > 0) &&
                <S.Bars title={`Complementares: ${calcularPercentual(complFormatted, total)}% (${complFormatted})`}
                        backgroundColor= {cores.complementares}
                        width={`${calcularPercentual(complFormatted, total)}%`}>
                    {`${calcularPercentual(complFormatted, total)}% (${complFormatted} min)`}
                </S.Bars>}
                                                                
            {(deslocFormatted > 0) &&
                <S.Bars title={`Deslocamentos: ${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted})`} 
                        backgroundColor= {cores.deslocamentos}
                        width={`${calcularPercentual(deslocFormatted, total)}%`}>
                    {`${calcularPercentual(deslocFormatted, total)}% (${deslocFormatted} min)`} 
                </S.Bars>}
        </S.CompanyBarContiner>
    </S.CompanyFooterBarsContiner> 
)