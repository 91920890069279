import React, { useEffect, useRef, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { ReportIcon } from '../../../Icons/Report';
import { TooltipContent } from '../../../components/Info/Tooltip';
import { Info } from '../../../components/Info';
import { useResourcesMetricsContext } from '../context/ResourcesMetricsContext';
import { getAvailableVsMonitoredResources } from '../../../services/api/TrackfyService/integration';
import { parseSelectOptions } from '../../../utils/Convert';
import { AvailableVsMonitoredData } from '../types';
import { IconLoading } from '../../../components/IconLoading';
import { Modal } from '../../../components/Modal';
import { IconExport } from '../../../Icons/Export';
import { useReactToPrint } from 'react-to-print';
import { ReportsHeader } from './ReportsHeader';
import { contervSecondsToTime } from '../../../utils/DateTime';
import TimeGranularitySelect from '../../filters/TimeGranularitySelect';

import * as S from '../styles';
import '../stylesheet.css';

export const ResourcesTotalVsMonitored: React.FC = () => {
  const [dataMetrics, setDataMetrics] = useState<AvailableVsMonitoredData[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const { companySelected, professionSelected, initialDate, finalDate, timeGranularitySelected, setTimeGranularitySelected } = useResourcesMetricsContext();
  const ref = useRef<HTMLDivElement>(null);
  const disclaimer = timeGranularitySelected == "week" ? "* Exibe a sumário das ultimas 4 semanas" :
    "* Exibe a sumário dos ultimos 3 meses";

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      if(showPreview) {
        setLoading(true);
      
        getAvailableVsMonitoredResources(
          parseSelectOptions(companySelected),
          parseSelectOptions(professionSelected),
          (new Date(initialDate.toString())).getTime(),
          (new Date(finalDate.toString())).getTime(),
          timeGranularitySelected,
          currentRequest.token
        ).then((response: AxiosResponse<AvailableVsMonitoredData[]>) => {
          setDataMetrics(response.data);
          setLoading(false);
        })
      } else {
        setLoading(false);
        setDataMetrics([]);
      }
    }

    return () => {
      mounted = false;
      currentRequest.cancel('ResourcesTimeDivision request canceled by the user.');
    };
  }, [showPreview]);

  const toggleExpand = () => {
    setShowPreview(!showPreview);
  };
  
  const calculatePercentage = (disponivel: number, medido: number) =>
    disponivel === 0 ? '0%' : `${((medido / disponivel) * 100).toFixed(2)}%`;

  const getReportsTooltipContent = (): TooltipContent[] => 
     [ { content: 'Comparativo entre disponível e medido com divisão por empresas e funções!' } ];

  const handlePrint = useReactToPrint({
    content: () => {
      const content = ref.current?.cloneNode(true);
      const printEl = document.createElement('div');
      if (content) printEl.appendChild(content);
      return printEl;
    },
  });
  
  return (
    <>
      <S.ResourceCard loading={loading} showPreview={showPreview} minHeight={"30px"}>
        <S.GranularitySelect>
          <S.GranularitySelectContainer>
            <TimeGranularitySelect setTimeGranularitySelected={setTimeGranularitySelected} 
                                   timeGranularitySelected={timeGranularitySelected}/>
          </S.GranularitySelectContainer>
          <S.GranularitySubheaderContainer>
            {(timeGranularitySelected != "daily") && <S.GranularitySubheader>{disclaimer}</S.GranularitySubheader>}
          </S.GranularitySubheaderContainer>
        </S.GranularitySelect>
      </S.ResourceCard>

      <S.ResourceCard loading={loading} showPreview={showPreview} minHeight={"150px"}>
        <S.SubtitleHeaderContainer>
          <S.SubtitleHeader>
            <S.HeaderText>
              <ReportIcon />
              Recursos: Disponível vs Medido
            </S.HeaderText>
            <Info 
              content={getReportsTooltipContent()} 
              position={'right'} 
              burndown={false}
              screen={'Relat'}
              eventName={''}
            />
            <S.GenerateButton title='Exportar' onClick={() => toggleExpand()} icon={<IconExport/>} />
          </S.SubtitleHeader>
        </S.SubtitleHeaderContainer>

        {showPreview && (
          <Modal visible={showPreview}>
            <S.ModalTableContainer>
              <S.ModalHeaderContainer>
              {!loading && <S.ToggleButton onClick={handlePrint}> {'Export PDF'}</S.ToggleButton>}
                <S.ToggleButton onClick={toggleExpand}> {'X close'}</S.ToggleButton>
              </S.ModalHeaderContainer>
              <S.TableContainer loading={loading} ref={ref}>
                {loading &&
                    <S.LoadingContainer>
                        <IconLoading />
                    </S.LoadingContainer>
                }
                {!loading && dataMetrics.map((dia, index) => (
                  <div key={dia.data + "_container"} className="pagebreak">
                    <div key={dia.data + "_header"}>
                      <ReportsHeader title="Recursos: Disponível vs Medido" margin="3rem !important" />
                    </div>
                    <S.TableWrapper key={index}>
                      <S.DataHeader>{dia.data}</S.DataHeader>
                      <S.Table>
                        <thead>
                          <tr>
                            <S.Th className='separator'>Função</S.Th>
                            <S.Th>Disponível</S.Th>
                            <S.Th>Medido</S.Th>
                            <S.Th className='separator'>%</S.Th>
                            <S.Th>Chegadas (Med.)</S.Th>
                            <S.Th>Saidas (Med.)</S.Th>
                          </tr>
                        </thead>
                        <tbody>
                          {(Object.entries(dia.empresas).length > 0) && 
                            Object.entries(dia.empresas).map(([empresa, funcoes], i) => {
                            const totalDisponivel = funcoes.reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0);
                            const totalMedido = funcoes.reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0);
                            const totalPorcentagem = calculatePercentage(totalDisponivel, totalMedido);
                            
                            const totalChegadas = funcoes.length > 0 ? 
                              funcoes.reduce((acc, curr) => acc + parseInt(curr.chegadas.toString()), 0) 
                                / funcoes.filter((funcao) => Number(funcao.chegadas.toString()) != 0).length : 0;

                            const totalSaidas = funcoes.length > 0 ? 
                              funcoes.reduce((acc, curr) => acc + parseInt(curr.saidas.toString()), 0) 
                                / funcoes.filter((funcao) => Number(funcao.saidas.toString()) != 0).length : 0;
                            
                            return (
                              <React.Fragment key={i}>
                                {i > 0 && <S.SectionGap />}
                                <S.SectionHeader>
                                  <S.Td colSpan={6}>  {empresa}</S.Td>
                                </S.SectionHeader>
                                {funcoes.map((func, idx) => (
                                  <S.Row loading={loading} key={idx} isEven={idx % 2 === 0}>
                                    <S.Td className='separator'>{func.funcao}</S.Td>
                                    <S.Td>{func.disponivel}</S.Td>
                                    <S.Td>{func.medido}</S.Td>
                                    <S.Td className='separator'>{calculatePercentage(func.disponivel, func.medido)}</S.Td>
                                    <S.Td>{contervSecondsToTime(func.chegadas) || '00:00:00'}</S.Td>
                                    <S.Td>{contervSecondsToTime(func.saidas) || '00:00:00'}</S.Td>
                                  </S.Row>
                                ))}
                                <S.FooterRow>
                                  <S.Td className='separator'>Total ({empresa})</S.Td>
                                  <S.Td>{totalDisponivel}</S.Td>
                                  <S.Td>{totalMedido}</S.Td>
                                  <S.Td className='separator'>{totalPorcentagem}</S.Td>
                                  <S.Td>{contervSecondsToTime(totalChegadas)}</S.Td>
                                  <S.Td>{contervSecondsToTime(totalSaidas)}</S.Td>
                                </S.FooterRow>
                              </React.Fragment>
                            );
                          })}
                        
                          <S.SectionGap />
                          <S.CompanyFooterRow>
                            <S.Td className='separator'>Total ({dia.data})</S.Td>
                            <S.Td>
                              {Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0)}
                            </S.Td>
                            <S.Td>
                              {Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0)}
                            </S.Td>
                            <S.Td className='separator'>
                              {calculatePercentage(
                                Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0),
                                Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0)
                              )}
                            </S.Td>
                            <S.Td>
                              {Object.values(dia.empresas).flat().length > 0 ? 
                                contervSecondsToTime(Object.values(dia.empresas).flat().reduce(
                                  (acc, curr) => acc + (curr.chegadas ? parseInt(curr.chegadas.toString()) : 0), 0) 
                                    / Object.values(dia.empresas).flat()
                                      .filter((item) => Number(item.chegadas.toString()) != 0).length) : 0 }
                            </S.Td>
                            <S.Td>
                              {Object.values(dia.empresas).flat().length > 0 ? 
                                contervSecondsToTime(Object.values(dia.empresas).flat().reduce(
                                  (acc, curr) => acc + (curr.saidas ? parseInt(curr.saidas.toString()) : 0), 0) 
                                    / Object.values(dia.empresas).flat()
                                      .filter((item) => Number(item.saidas.toString()) != 0).length) : 0 }
                            </S.Td>
                          </S.CompanyFooterRow>
                        </tbody>
                      </S.Table>
                    </S.TableWrapper>
                  </div>
                ))}
              </S.TableContainer>
            </S.ModalTableContainer>
          </Modal>
        )}
      </S.ResourceCard>
    </>
  );
};