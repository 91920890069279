import React, { useEffect, useRef, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useResourcesMetricsContext } from '../../context/ResourcesMetricsContext';
import { TimeDivisionData } from '../../types';
import { useReactToPrint } from 'react-to-print';
import { TooltipContent } from '../../../../components/Info/Tooltip';
import { parseSelectOptions } from '../../../../utils/Convert';
import { getResourcesTimeDivision } from '../../../../services/api/TrackfyService/integration';
import { ReportIcon } from '../../../../Icons/Report';
import { Info } from '../../../../components/Info';
import { IconExport } from '../../../../Icons/Export';
import { Modal } from '../../../../components/Modal';
import { IconLoading } from '../../../../components/IconLoading';
import { ReportsHeader } from '../ReportsHeader';

import * as S from '../../styles';
import '../../stylesheet.css';
import { ResourcesTimeCompanySection } from './ResourcesTimeCompanySection';

export const ResourcesTimeDivisionBars: React.FC = () => {
    const [dataMetrics, setDataMetrics] = useState<TimeDivisionData[]>([]);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { companySelected, professionSelected, initialDate, finalDate, timeGranularitySelected } = useResourcesMetricsContext();
    const ref = useRef<HTMLDivElement>(null);
    const cores = {
      produtivas: '#2f4cdd', //#4CAF50',  
      pt: '#2196F3',
      complementares: '#00DDDD', 
      deslocamentos: '#FFC107',
      improdutivas: '#CCC',
  };

    useEffect(() => {
        let mounted = true;
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    
        if (mounted) {

          if(showPreview) {
            setLoading(true);
    
            getResourcesTimeDivision(
              parseSelectOptions(companySelected),
              parseSelectOptions(professionSelected),
              (new Date(initialDate.toString())).getTime(),
              (new Date(finalDate.toString())).getTime(),
              timeGranularitySelected,
              currentRequest.token
            ).then((response: AxiosResponse<TimeDivisionData[]>) => {
              setDataMetrics(response.data);
              setLoading(false);
            })
          } 
          else {
            setLoading(false);
            setDataMetrics([]);
          }

        }

        return () => {
          mounted = false;
          setDataMetrics([]);
          currentRequest.cancel('ResourcesTimeDivision request canceled by the user.');
        };
      }, [showPreview]);
    
    const toggleExpand = () => (setShowPreview(!showPreview));
    
    const getCurrentValue = (value?: number) => value ? parseInt(value.toString()) : 0;
    
    const getReportsTooltipContent = (): TooltipContent[] => 
         [
            {
              title: 'Produtivas - ',
              content: 'Tempo registrado em áreas monitoradas, exceto casas de PT, portarias, refeitorios e acessos.',
            },
            {
              title: 'Retirada de PT - ',
              content: 'Tempo registrado em casas de PT.',
            },
            {
              title: 'Complementares - ',
              content: 'Tempo registrado em portarias, refeitórios e acessos.',
            },
            {
              title: 'Deslocamentos - ',
              content: 'Tempo registrado em qualquer área, inferior a 2 minutos. Indicam movimentação.',
            },
            //{
              //title: 'Improdutivas - ',
              //content: 'Tempo complementar qualquer área em que não há captação da solução.',
            //}
    ];
    
    const handlePrint = useReactToPrint({
        content: () => {
          const content = ref.current?.cloneNode(true);
          const printEl = document.createElement('div');;
          if (content) printEl.appendChild(content);
          return printEl;
        },
    });

    const calcularPercentual = (valor: number, total: number) => parseFloat( ((valor / total) * 100).toFixed(2) );
    
    return (
      <S.ResourceCard loading={loading} showPreview={showPreview}>
        <S.SubtitleHeader>
          <S.HeaderText>
            <ReportIcon />
            Recursos: Divisão de Tempo
          </S.HeaderText>
          <Info 
            content={getReportsTooltipContent()} 
            position={'right'} 
            burndown={false}
            screen={'Relat'}
            eventName={''}
          />
          <S.GenerateButton title='Exportar' onClick={() => toggleExpand()} icon={<IconExport/>} />
        </S.SubtitleHeader>

        {showPreview && (
          <Modal visible={showPreview}>
            <S.ModalTableContainer>
              <S.ModalHeaderContainer>
                {!loading && <S.ToggleButton onClick={handlePrint}> {'Export PDF'}</S.ToggleButton>}
                <S.ToggleButton onClick={toggleExpand}> {'X close'}</S.ToggleButton>
              </S.ModalHeaderContainer>
              <div>
                {loading &&
                    <S.LoadingContainer>
                        <IconLoading />
                    </S.LoadingContainer>
                }

                {!loading && (dataMetrics.length > 0) &&
                  <S.ReportContentContiner ref={ref}>
                    {dataMetrics.map((item, index) => {
                      const titles = Object.keys(item.empresas);

                      const header = 
                        <S.ReportHeaderLegendContiner key={item.data + "_header"} className="pagebreakbar">
                                <ReportsHeader title="Recursos: Divisão de Tempo" margin="0px !important" />
                                <S.LegendContainer>
                                  <S.LegendItem color="#2f4cdd">
                                    Produtivas: Tempo registrado em áreas monitoradas, exceto casas de PT, portarias, refeitorios e acessos
                                  </S.LegendItem>
                                  <S.LegendItem color="#2196F3">
                                    Retirada de PT: Tempo registrado nas casas de PT
                                  </S.LegendItem>
                                  <S.LegendItem color="#00DDDD">
                                    Complementares: Tempo registrado em portarias, refeitórios e acessos
                                  </S.LegendItem>
                                  <S.LegendItem color="#FFC107">
                                    Deslocamentos: Tempo registrado em qualquer área, inferior a 2 minutos ininterruptos
                                  </S.LegendItem>
                                  {/*<S.LegendItem color="#CCC">
                                    Improdutivas: Tempo complementar, registrado em áreas não monitoradas pela solução
                                  </S.LegendItem>*/}
                                </S.LegendContainer>
                        </S.ReportHeaderLegendContiner>

                      const container = <ResourcesTimeCompanySection index={index}
                                                                     item={item}
                                                                     titles = {titles}
                                                                     cores = {cores} />

                      const section = (index > 0) ? <S.SectionGapDiv/> : <></>;

                      return [ section, header, container ];
                              
                    })}
                  </S.ReportContentContiner>
                }
              </div>
            </S.ModalTableContainer>
          </Modal>
        )}
    </S.ResourceCard>
    );
}