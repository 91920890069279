import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useIsMounted } from '../../hooks';
import { getGps } from '../../services/api/TrackfyService/integration';
import { brokerData, LocationsProps, NotificationAlerts } from './types';

export const Locations: React.FC<LocationsProps> = ({setNotifications}) => {
    const isMounted = useIsMounted();
    const [coordinates, setCoordinates] = useState<brokerData[]>([]);
    const locations: React.ReactElement[] = [];
    
    const getCurrentCoordinates = (currentRequest: CancelTokenSource) => {
        getGps(currentRequest.token).then((data: AxiosResponse<any>) => {
          const response: brokerData = data.data;

          if(response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0)) {
            setCoordinates([response]);

            if(response.abw?.sos){
                const newNotification: NotificationAlerts = { id: Date.now(), 
                                                              tag: response.abw.tag, 
                                                              time:response.abw.receivedAt.toString().substring(0,16),
                                                              sos: response.abw.sos };
                setNotifications([newNotification]);
                //setNotifications((prev) => [newNotification, ...prev]);
            }
         }

        })
        .catch((err) => console.log(err));
    }
    
    useEffect(() => {     
        isMounted.current = true;
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
        let interval: NodeJS.Timeout | undefined = undefined;
    
        getCurrentCoordinates(currentRequest);
    
        const fetchData = () => getCurrentCoordinates(currentRequest);
    
        interval = setInterval(fetchData, 10000);
    
        return () => {
            if (interval) clearInterval(interval);
            currentRequest.cancel('Gps route request canceled by the user.'); 
            isMounted.current = false;      
        };
    }, [])

    return (<>
                {coordinates.map((coordinate: brokerData, index: number) => {
                    if(coordinate.moko) 
                        locations.push(<Marker key={index + "_" + coordinate.moko.tag + "_" + coordinate.moko.receivedAt} 
                                               latitude={coordinate.moko.lat} longitude={coordinate.moko.lng} 
                                               color={coordinate.moko.sos ? "#FF0000" :"blue"}></Marker>);
                    if(coordinate.abw)
                        locations.push(<Marker key={index + "_" + coordinate.abw.tag + "_" + coordinate.abw.receivedAt}
                                               latitude={coordinate.abw.lat} longitude={coordinate.abw.lng}  
                                               color={coordinate.abw.sos ? "#FF0000" : "#479990"}></Marker>);
                    return locations;
                })}
            </>)
}


/*
          if(
            (response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0))
            || (response.moko && response.moko.sos && (response.moko.lat > -30 && response.moko.lat <= 0) && (response.moko.lng > -50 && response.moko.lng < 0))
          ) {
*/