import React from 'react';
import styled, { keyframes } from 'styled-components';

import { NotificationAlerts, NotificationCardProps, NotificationSystemProps } from './types';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationsContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 0px;
  height: 60%;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        //background: #f3f3f3;
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }
`;

const NotificationCard = styled.div<NotificationCardProps>`
  //background: #479990;
  background: ${({ sos }) => (sos ? '#FF0000' : '#479990')};
  color: ${({ sos }) => (sos ? 'white' : 'black')};
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.3s ease-out;
  font-weight: bold;
  font-size: small;
`;

const NotificationTitle = styled.div`
  text-align: center;
  margin-bottom: 7px;
`;

const NotificationData = styled.div`
  font-size: smaller;
`;

const NotificationSystem: React.FC<NotificationSystemProps> = ({notifications}) => {
  return (
      <NotificationsContainer>
        {notifications.map((notif: NotificationAlerts) => (
          <NotificationCard key={notif.id} sos={notif.sos}>
            <NotificationTitle>Notificação - SOS</NotificationTitle>
            <NotificationData>Tag: {notif.tag}</NotificationData>
            <NotificationData>Data e Hora: {notif.time}</NotificationData>
          </NotificationCard>
        ))}
      </NotificationsContainer>
  );
};

export default NotificationSystem;