import React, { useState } from 'react';
import { MapProvider, Map } from 'react-map-gl';

import { Locations } from './Locations';
import NotificationSystem from './NotificationSystem';
import { GpsLocationContainerProps, MapCoordinates, NotificationAlerts } from './types';
import { LoadingLocation } from './LoadingLocation';

export const GpsLocationContainer: React.FC<GpsLocationContainerProps> = ({authData}: GpsLocationContainerProps) => {
  const [coordinates, setCoordinates] = useState<MapCoordinates>({latitude:0, longitude:0, zoom:3});
  const [notifications, setNotifications] = useState<NotificationAlerts[]>([]);
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v12';

  return (
      <div>
        <MapProvider>
          <Map
            id="gpstrackfymap"
            initialViewState={{longitude: 0, latitude: 0, zoom: 0}}
            style={{ height: '100vh' }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ''}
            mapStyle={mapStyle}
            scrollZoom={true}
            {...coordinates}
          >
            {(coordinates.latitude == 0) && <LoadingLocation/>}
            {<Locations setNotifications={setNotifications} setGeojson={() => {}} setMapLocation={setCoordinates}/>}
            {<NotificationSystem notifications={notifications} />}
          </Map>
        </MapProvider>
      </div>
  );
}