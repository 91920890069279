import React, { useState } from 'react';
import { MapProvider, Map } from 'react-map-gl';

import { Locations } from './Locations';
import NotificationSystem from './NotificationSystem';
import { GpsLocationContainerProps, NotificationAlerts } from './types';

export const GpsLocationContainer: React.FC<GpsLocationContainerProps> = ({authData}: GpsLocationContainerProps) => {
  const [notifications, setNotifications] = useState<NotificationAlerts[]>([]);
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v12';

  return (
      <div>
        <MapProvider>
          <Map
            id="gpstrackfymap"
            initialViewState={{
              //longitude: -38.299008,
              //latitude: -12.873216,
              longitude: -38.388009,
              latitude: -12.9232164,
              zoom: 18,
            }}
            style={{ height: '100vh' }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
            mapStyle={mapStyle}
            scrollZoom={true}
          >
            {<Locations setNotifications={setNotifications}/>}
            {<NotificationSystem notifications={notifications} />}
          </Map>
        </MapProvider>
      </div>
  );
}