import React from 'react';
import { IconLoading } from '../../components/IconLoading';

export const LoadingLocation: React.FC = () => {

    return (
        <div style={{ width: '100%', marginTop:'25%', display:'flex', justifyContent:'center' }}>
            <div style={{display:'flex', zIndex:'999'}}>
                <IconLoading />
                <div style={{ color: 'white', fontSize: '25px', marginTop: '25px', marginLeft: '10px' }}>
                    Carregando Localização
                </div>
            </div>
        </div>
    )
}