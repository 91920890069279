export enum RouteKey {
  emergencia = 'emergencia',
  indicadores = 'indicadores',
  analises = 'analises',
  login = 'login',
  monitoramento = 'monitoramento',
  relatorios = 'relatorios',
  sumario = 'sumario',
  sumariodemaquinas = 'sumariodemaquinas',
  planejamento = 'planejamento',
  curvas = 'curvas',
  informes = 'informes',
  vinculacaodecolaboradores = 'vinculacaodecolaboradores',
  deslocamento = 'deslocamento',
  temporeal = 'temporeal',
  calibracao = 'calibracao',
  onepage = 'onepage',
  suporte = 'suporte',
  incidentes = 'incidentes',
  scannerstatus = 'scannerstatus',
  usuarios = 'usuarios',
  novasenha = 'novasenha',
  cadastrocliente = 'cadastrocliente',
  telahome = 'telahome',
  infleet = 'infleet',
  logs = 'logs',
  sumariorecursos = 'sumariorecursos',
  gps = 'gps',
  gpsroute = 'gpsroute',
}
