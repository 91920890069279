import React, { useEffect, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { MapProvider, Map } from 'react-map-gl';

import NotificationSystem from './NotificationSystem';
import { GpsLocationContainerProps, MapCoordinates, NotificationAlerts } from './types';
import { Routes } from './Routes';
import { useIsMounted } from '../../hooks';
import { CoordinatesResponse, getMapCoordinates } from '../../services/api/TrackfyService';

export const GpsRouteContainer: React.FC<GpsLocationContainerProps> = ({authData}: GpsLocationContainerProps) => {
  const [notifications, setNotifications] = useState<NotificationAlerts[]>([]);
  const [mapInit, setMapInit] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<MapCoordinates>({latitude:0, longitude:0, zoom:18});
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v12';
  const isMounted = useIsMounted();
  
  useEffect(() => {
    isMounted.current = true;

    if (isMounted.current) {
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();

        getMapCoordinates(currentRequest.token).then((data: CoordinatesResponse) => {
            setCoordinates({
                latitude: Number(data.latitude),
                longitude: Number(data.longitude),
                zoom: Number(data.zoom)
            })
            setMapInit(true);
        })
        .catch((_error) => console.log(_error));
    }

    return () => { isMounted.current = false };
  }, []);

  if(mapInit)
    return (
        <div>
          <MapProvider>
            <Map
              id="gpstrackfyroutemap"
              initialViewState={{
                //longitude: -38.3937273,
                //latitude: -12.9234306,
                //zoom: 18,
                //longitude: -38.299008,
                //latitude: -12.873216,
                longitude: coordinates.longitude,
                latitude: coordinates.latitude,
                zoom: coordinates.zoom
              }}
              style={{ height: '100vh' }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
              mapStyle={mapStyle}
              scrollZoom={true}
            >
              {<Routes setNotifications={setNotifications}/>}
              {<NotificationSystem notifications={notifications} />}
            </Map>
          </MapProvider>
        </div>
    );
  
  return <></>;
}