import styled from 'styled-components';
import { Button } from '../../components/Buttons/Button';

interface props {
    colSpan?: string;
    isEven?: boolean;
    showPreview?: boolean;
    minHeight?: string;
    loading: boolean;
}

interface barprops {
  backgroundColor: string;
  width: string;
}

interface ReportHeaderProps {
  margin: string;
}

interface CompanyFooterBarsContinerProps {
  dailyTotal: boolean;
}

export const ResourceCard = styled.div<props>`
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '50px')};
  max-height: ${({ showPreview }) => (showPreview ? '700px' : '100px')};
  padding: 1.6rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: ${({ showPreview }) => (showPreview ? 'scroll' : 'hidden')};
  transition: max-height 0.3s ease, padding 0.3s ease;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  color: #0056b3;
  margin-bottom: 20px;
  align-self: flex-start;

  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 3px;
`;

export const SubtitleHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitleHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.8rem;
  gap: 1.6rem;

  & svg {
    margin-right: 1.6rem;
  }
`;

export const GranularitySelect = styled.div`
  display: flex;
  font-size: small;
  align-items: center;
`;

export const GranularitySelectContainer = styled.div`
  width: 498px;
`;

export const GranularitySubheaderContainer = styled.div`
  width: 400px;
`;

export const GranularitySubheader = styled.h5`
  margin-left: 15px;
  margin-top: 25px;
`;

export const HeaderText = styled.span`
    font-family: Work Sans;
    font-weight: 500;
    font-size: 1.6rem;
    color: #0e1742;
`;

export const GenerateButton = styled(Button)`
  border: solid 2px #2F4CDD;
  background: #ffffff;
  color: #2F4CDD;
  width: 140px;
  height: 45px;
  gap: 14px;
  border-radius: 8px;

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.7;
  }
`

//----------------------------------------------------------------------------------------------------------

export const ReportHeaderContainer =  styled.header<ReportHeaderProps>`
  width: 100vw;
  padding: 0.8rem 2.2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Work Sans;
  margin-bottom: ${(props) => props.margin || "3rem !important"};
`;

export const ReportHeaderH = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #2f4cdd;
  margin: 0;
  margin-top: 10px;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 20px;
  gap: 15px;
`;

export const DataHeader = styled.h3`
  color: #007BFF;

  border-top-style: dotted;
  border-bottom-style: dotted;
  border-width: 2px;
  border-color: #aaa;
  margin-bottom: 15px;
`;

export const ModalTableContainer = styled.div`
  //display: flex;
  //justify-content: space-between;

  width: 100%;
  min-width: 800px;
  min-height: 400px;
  max-height: 700px;
  padding: 1.6rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y:'scroll'
  transition: max-height 0.3s ease, padding 0.3s ease;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
`;

//----------------------------------------------------------------------------------------------------------

export const TableContainer = styled.div<props>`
  width: 100%;
  padding: 20px;
  display: ${({ loading }) => (loading ? 'flex' : 'table')};
  margin-top: ${({ loading }) => (loading ? '10px' : '0px')};
  justify-content: center;
`;

export const TableWrapper = styled.div`
  margin-bottom: 40px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;

  border-radius: 5px;
  overflow: hidden;
`;

export const Th = styled.th`
  background-color: #273dab; 
  //#007BFF;
  color: white;
  padding: 3px;
  border: 1px solid #ccc;
  font-size: smaller;
`;

export const Td = styled.td`
  padding: 2px;
  border: 1px solid #ccc;
  width: 100px;
  font-size: smaller;
  text-align: center;
`;

export const Row = styled.tr<props>`
  background-color: ${props => props.isEven ? '#f2f2f2' : 'white'};
`;

export const SectionHeader = styled.tr`
  background-color: #7180be;
  font-weight: bold;

  height: 18px;
`;

export const FooterRow = styled.tr`
  background-color: #e0e0e0;
  font-weight: bold;
  height: 18px;
  //padding: 4px;
`;

export const CompanyFooterRow = styled.tr`
  background-color: #a2a2a2; //#111f67;
  font-weight: bold;
  height: 28px;
  padding: 3px;
`

export const SectionGap = styled.tr`
  height: 15px;
`;

//----------------------------------------------------------------------------------------------------------

export const ReportContentContiner = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ReportDataContiner = styled.div`
  marginBottom: 20px;
`;

export const ReportHeaderLegendContiner = styled.div`
  margin-bottom: 25px;
`;

export const ReportBarsContiner = styled.div`
  display: flex;
  height: 25px;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 5px;
`;

export const CompanyBarContiner = styled.div`
  display: flex;
  height: 35px;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 5px;
  margin-right: 5px;
`;

export const Bars = styled.div<barprops>`
  background-color: ${(barprops) => barprops.backgroundColor || "#fff" };
  width: ${(barprops) => barprops.width || "0%"};
  min-width: 80px;

  display: flex;
  justify-content: center;
  align-items: center; 
  color: white; 
  font-weight: bold;
  font-size: xx-small;
  position: relative; 
`;

export const SectionGapDiv = styled.div`
  height: 15px;
`;

export const CompanyGapDiv = styled.div`
  height: 3px;
`;

export const CompanyBarsContiner = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
  gap: 3px;
`;

export const CompanyFooterBarsContiner = styled.div<CompanyFooterBarsContinerProps>` 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ dailyTotal }) => (dailyTotal ? '5px' : '20px')};
  margin-bottom: ${({ dailyTotal }) => (dailyTotal ? '10px' : '5px')};
  gap: 3px;
  background-color: ${({ dailyTotal }) => (dailyTotal ? '#ccc' : '#eee')};
  height: 40px;
  align-items: center;
  border-radius: 3px;
`;

export const CompanyTitle = styled.h3`
  margin-bottom: 5px;
  border-bottom-style: inset;
`;

export const FunctionTitle = styled.h3`
  margin-bottom: 0px;
  font-size: small;
  font-weight: 500;
  width: 15%;
`;

export const CompanyTotalTitle = styled.h3`
  margin-bottom: 0px;
  font-size: small;
  font-weight: bold;
  width: 15%;
  margin-left: 5px;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1px;
  padding: 0.0rem 2.2rem;
  margin-top: 2px;
  font_weight: bold;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #333;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.color || "#ccc"}; // Cor da legenda
    border-radius: 50%;
    margin-right: 0.5rem;
  }
`;