import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useIsMounted } from '../../hooks';
import { getGps } from '../../services/api/TrackfyService/integration';
import { brokerData, LocationsProps, NotificationAlerts } from './types';

export const Routes: React.FC<LocationsProps> = ({setNotifications}) => {
    const isMounted = useIsMounted();
    const [coordinates, setCoordinates] = useState<brokerData[]>([]);
    const locations: React.ReactElement[] = [];
    
    const getCurrentCoordinates = (currentRequest: CancelTokenSource) => {
        getGps(currentRequest.token).then((data: AxiosResponse<any>) => {
            const response: brokerData = data.data;

            if(response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0)) {
                setCoordinates((prevState) => {

                    if(JSON.stringify(prevState[prevState.length - 1]) != JSON.stringify(response)) {    
                        if(response.abw?.sos){
                            const newNotification: NotificationAlerts = { id: Date.now(), 
                                                                        tag: response.abw.tag, 
                                                                        time:response.abw.receivedAt.toString().substring(0,16),
                                                                        sos: response.abw.sos };
                            setNotifications((prev) => [newNotification, ...prev]);
                        }

                        return ([...prevState, response]);
                    }

                    return prevState;
                });
            }
        })
        .catch((err) => console.log(err));
    }
    
    useEffect(() => {      
        isMounted.current = true;
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
        let interval: NodeJS.Timeout | undefined = undefined;
    
        getCurrentCoordinates(currentRequest);
    
        const fetchData = () => getCurrentCoordinates(currentRequest);
    
        interval = setInterval(fetchData, 10000);
    
        return () => {
            if (interval) clearInterval(interval);
            currentRequest.cancel('Gps request canceled by the user.'); 
            isMounted.current = false;      
        };
    }, [])

    return (<>
                {coordinates.map((coordinate: brokerData, index: number) => {
                    if(coordinate.moko) 
                        locations.push(<Marker latitude={coordinate.moko.lat} longitude={coordinate.moko.lng} color="blue">
                                <div style={{ background: "aqua",
                                            color: "blue",
                                            padding: "5px",
                                            borderRadius: "50%",
                                            textAlign: "center",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            transform: "translate(-50%, -50%)",
                                        }}>
                                    {index}
                                </div>
                            </Marker>);
                    
                    if(coordinate.abw) 
                        locations.push(<Marker latitude={coordinate.abw.lat} longitude={coordinate.abw.lng}  color="#479990">
                             <div style={{  background: coordinate.abw.sos ? "#FF0000" : "aquamarine",
                                            color: coordinate.abw.sos ? "white" : "#479990",
                                            padding: "5px",
                                            borderRadius: "50%",
                                            textAlign: "center",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            transform: "translate(-50%, -50%)",
                                        }}>
                                    {index}
                                </div>
                            </Marker>);

                    return locations;
                })}
            </>)
}


/*if(
    (response.abw
        && (response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0))
        || (response.moko && response.moko.sos && (response.moko.lat > -30 && response.moko.lat <= 0) && (response.moko.lng > -50 && response.moko.lng < 0)))
  ) {
*/