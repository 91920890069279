import React, { useEffect } from 'react';

import { useAuthContext } from '../../../stores/AuthContext';
import { useResourcesMetricsContext } from '../context/ResourcesMetricsContext';
import { NewDateRangePicker } from '../../../components/Forms/NewDateRangePicker';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuth } from '../../../hooks';
import CompanyMultiSelect from '../../filters/CompanyMultiSelect';
import ProfessionMultiSelect from '../../filters/ProfessionMultiSelect';
import { FilterIconVuesax } from '../../../Icons/Filter-Vuesax';
import { FilterCancelButton } from '../../../components/Buttons/FilterCancel';
import Calendar from '../../../assets/calendar_alternative.svg';

import { OptionsContainer } from '../../../components/PageStructure/styles';
import * as S from '../../../styles/structure';

export const ResourcesMetricsOptions: React.FC = () => {
  const { companySelected,
          setCompanySelected,
          professionSelected,
          setProfessionSelected,
          updatePeriod,
          clearFilters,
          date,
          setDate,
          timeGranularitySelected, } = useResourcesMetricsContext();
  const { authData } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  useEffect(() => mixpanelTrack(monitorAgent, 'Relatórios de recursos Brask', userName, authData, 'RelatoriosBraskFiltro'), []);

  return (
    <OptionsContainer>
      <FilterIconVuesax width={40} height={40} strokeWidth='1.0'/>

      {compIds != 'a' && !compIds.includes(',') ? (<></>) : 
      (<S.SimpleContainer>
          <CompanyMultiSelect 
            itemsSelected={companySelected}
            setItemsSelected={setCompanySelected}
            screen={`Relatórios de recursos Brask`}
            eventName={'RelatoriosBrask>FiltroEmp'}
          />
        </S.SimpleContainer>
      )}

      <S.SimpleContainer>
        <ProfessionMultiSelect 
            itemsSelected={professionSelected}
            setItemsSelected={setProfessionSelected}
            screen={`Relatórios de recursos Brask`}
            eventName={'RelatoriosBrask>FiltroFunc'}
        />
      </S.SimpleContainer>

      <S.SimpleContainer>
        <NewDateRangePicker
          updatePeriod={updatePeriod}
          selectRange
          loadDate={new Date()}
          date={date}
          setDate={setDate}
          customIcon={Calendar}
          limitDateRange={15}
          disabled={timeGranularitySelected != "daily"}
        />
      </S.SimpleContainer>

      <FilterCancelButton onClick={clearFilters} />
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};
