import React, { useState } from 'react';
import Calendar from 'react-calendar';

import { useDateRangePicker } from './hooks/useDateRangePicker';
import {
  CalendarContainer,
  CalendarInputGroup,
  CalendarInput,
  CalendarGroup,
  CalendarOptsContainer,
  CalendarConfirm,
  CalendarCurrent,
  CalendarCancel,
} from './styles';
import 'react-calendar/dist/Calendar.css';

export interface DateRangeProps {
  updatePeriod: (initial: any, final: any) => void;
  selectRange: boolean;
  loadDate: Date;
  customIcon?: string;
  id?: string;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  screen?: string;
  component?: string;
  maxDate?: Date;
  showToday?: boolean;
  date: any;
  setDate: (value: any) => void;
  limitDateRange?: number;
  placeholder?: string;
  disabled? : boolean;
}

export const NewDateRangePicker: React.FC<DateRangeProps> = (props) => {
  const { updatePeriod, customIcon, id, inputStyle, containerStyle, date, setDate, placeholder, limitDateRange, 
    disabled } = props;

  const {
    calendar,
    setCalendar,
    handleClickToday,
    handleConfirmDate,
    onChange,
    updateCalendarState,
    toggleCalendar,
  } = useDateRangePicker(date, setDate);

  const [currentInitialDateSelected, setCurrentInitialDateSelected] = useState<Date>();
  const getMaxDateLimited = () => {
    if (!currentInitialDateSelected) return new Date();
    if (limitDateRange === undefined) return;
    const newDate = new Date(
      currentInitialDateSelected.getFullYear(),
      currentInitialDateSelected.getMonth(),
      currentInitialDateSelected.getDate() + limitDateRange
    );
    return newDate > new Date() ? new Date() : newDate;
  };

  const getMinDateLimited = () => {
    if (!currentInitialDateSelected) return;
    if (limitDateRange === undefined) return;
    return new Date(
      currentInitialDateSelected.getFullYear(),
      currentInitialDateSelected.getMonth(),
      currentInitialDateSelected.getDate() - limitDateRange
    );
  };

  return (
    <CalendarContainer style={containerStyle}>
      <CalendarInputGroup onBlur={toggleCalendar} onClick={toggleCalendar}>
        <CalendarInput
          id={id}
          customIcon={customIcon}
          readOnly
          type="text"
          className="icon-calendar"
          value={date}
          style={inputStyle}
          placeholder={placeholder}
          disabled={disabled }
        />
      </CalendarInputGroup>
      {calendar && (
        <CalendarGroup onClick={() => updateCalendarState(true)} onBlur={() => updateCalendarState(false)}>
          <Calendar
            locale="pt-BR"
            maxDate={limitDateRange ? getMaxDateLimited() : new Date()}
            minDate={getMinDateLimited()}
            //maxDate={getFollowingFriday(new Date())} if it needs to check planning in the future
            className="input-calendar"
            onChange={onChange}
            selectRange
            onClickDay={(v) => {
              if (limitDateRange) {
                setCurrentInitialDateSelected(v);
              }
              updateCalendarState(false);
            }}
            onActiveStartDateChange={(props) => console.log(props)}
          />
          <CalendarOptsContainer>
            <CalendarCancel onClick={() => setCalendar(!calendar)}>
              <span>Cancelar</span>
            </CalendarCancel>
            <CalendarCurrent onClick={() => handleClickToday(updatePeriod)}>
              <span>Hoje</span>
            </CalendarCurrent>
            <CalendarConfirm onClick={() => handleConfirmDate(updatePeriod)}>
              <span>Ok</span>
            </CalendarConfirm>
          </CalendarOptsContainer>
        </CalendarGroup>
      )}
    </CalendarContainer>
  );
};
